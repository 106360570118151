import { CalendarIcon } from "@heroicons/react/24/outline"
import BanUserButton from "components/BanUserButton"
import GeneratingPage from "components/GeneratingPage"
import HologramGallery from "components/HologramGallery"
import { UserProfileImage } from "components/UserProfileImage"
import { EmbedHologram } from "lib/hologramProps"
import { ssgInit } from "lib/trpc/ssg-init"
import { GetStaticPropsContext } from "next"
import { NextSeo } from "next-seo"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { ErrorPage } from "./404"
import { useBlocksUserStore } from "hooks/useStore"
import { trpc } from "@/lib/trpc/trpc"

export async function getStaticPaths() {
	return {
		paths: [],
		fallback: true,
	}
}

export async function getStaticProps(context: GetStaticPropsContext) {
	const username = context?.params?.username?.toString()?.toLowerCase()

	const ssg = await ssgInit()

	let user = username ? await ssg.user.findUser.fetch({ username }) : undefined

	return {
		props: {
			username: user?.username,
			id: user?.id,
			displayName: user?.displayName,
			picture: user?.picture,
			trpcState: ssg.dehydrate(),
			uuid: user?.uuid,
		},
		revalidate: 60,
	}
}

export default function Profile({ username, createdAt, id, displayName, picture, uuid }) {
	const router = useRouter()
	const [formattedDate, setFormattedDate] = useState<JSX.Element>(<></>)

	const { data, isFetchingNextPage, fetchNextPage, hasNextPage } =
		trpc.hologram.getHolograms.useInfiniteQuery(
			{ limit: 16, userId: id },
			{
				getNextPageParam: (lastPage) => lastPage.nextCursor,
			},
		)

	const holograms = data?.pages.flatMap((page) => page.holograms)

	// Date must be set client side to avoid hydration mismatch
	useEffect(() => {
		if (createdAt) {
			const cleanDate = new Date(createdAt)
			setFormattedDate(
				<>
					{cleanDate.toLocaleString("en-US", {
						month: "long",
					})}{" "}
					{cleanDate.getDate()}, {cleanDate.getFullYear()}
				</>,
			)
		}
	}, [createdAt])

	const isLoading = useBlocksUserStore((s) => s.isLoading)
	if (router.isFallback) {
		return <>{isLoading ? <GeneratingPage /> : ""}</>
	}

	if (!id) {
		return <ErrorPage code={404} />
	}

	return (
		<>
			<NextSeo title={`${displayName}`} canonical={`/${username}`} />
			<div className="mx-auto mt-4 flex max-w-screen-2xl flex-col px-3 md:mt-2 md:flex-row md:space-x-6 md:px-6">
				<div className="flex flex-col items-center gap-2 md:mt-6 md:place-items-start md:space-y-4">
					<h1 className="order-2 w-full break-words text-3xl font-bold md:order-1 md:w-72 md:text-4xl">
						{displayName}
					</h1>

					<div className="order-1 mr-4 md:order-2 md:mr-0 md:w-full md:max-w-[18rem]">
						<UserProfileImage user={{ id: id, picture: picture, uuid }} className="w-16 md:w-full" />
					</div>

					<div className="order-3 flex flex-col items-start gap-1 self-start opacity-60">
						<div className="flex justify-start gap-3">
							<CalendarIcon className="w-5 dark:fill-white" />
							<div className="text-base dark:text-white">Joined {formattedDate}</div>
						</div>
						{/* {isCurrentUser && (
							<div className="flex items-center justify-start gap-3">
								<PhotoIcon className="w-5 fill-black dark:fill-white" />
								<div className="text-base dark:text-white">
									<span className="font-bold">
										{remainingConversions}/{conversionLimit}
									</span>{" "}
									3D image uploads remaining
								</div>
							</div>
						)} */}
						<BanUserButton id={id} />
					</div>
				</div>
				<div className="relative mt-4 min-h-[50vh] w-full">
					<HologramGallery
						holograms={holograms as unknown as EmbedHologram[]}
						isFetchingNextPage={isFetchingNextPage}
						fetchNextPage={fetchNextPage}
						hasNextPage={hasNextPage}
					/>
				</div>
			</div>
		</>
	)
}
